import messageConfigs from '../../Helper/PopupMessageConfig';
import { findRolesList } from '../../Helper/SystemManager';
import {
  UPDATE_AUTH,
  ADD_ADMIN_USER,
  CHANGE_ADMIN_VIEW,
} from '../action/actionTypes';
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAIL,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_DATA_FAIL,
  SET_WELCOME_VIDEO_VIEWED,
  CLEAR_SESSION,
  FETCH_RESET_PASSWORD_DATA_REQUEST,
  FETCH_RESET_PASSWORD_DATA_SUCCESS,
  FETCH_RESET_PASSWORD_DATA_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  FETCH_COMPANY_PROFILE_REQUEST,
  FETCH_COMPANY_PROFILE_SUCCESS,
  FETCH_COMPANY_PROFILE_FAIL,
  SYNC_FEDERATE_LOGIN_DATA_REQUEST,
  SYNC_FEDERATE_LOGIN_DATA_SUCCESS,
  SYNC_FEDERATE_LOGIN_DATA_FAIL,
  SET_IS_FEDERATED_LOGIN,
  SET_GEO_LOCATION,
  FETCH_S3_BUCKET_DATA_REQUEST,
  FETCH_S3_BUCKET_DATA_SUCCESS,
  FETCH_S3_BUCKET_DATA_FAIL,
} from '../actionTypes/auth';
const initailState = {
  userEmail: '',
  isAdminUser: false,
  isAdminView: false,
  access_token: '',
  refresh_token: '',
  session_token: '',
  auth_error: false,
  authUserDataLoading: false,
  authUserDataError: null,
  userData: { userName: '', preferredUsername: '' },
  resetPasswordUserDataRequestLoading: false,
  resetPasswordUserData: null,
  resetPasswordUserDataError: null,
  isWelcomeVideoViewed: false,
  resetPasswordReqLoading: false,
  resetPasswordReqSuccess: false,
  resetPasswordReqFail: false,
  federateLoginData: false,
  isFederateLogin: false,
  federatedLoginError: false,
  geoLocation: null,
  s3BucketDataLoading: false,
  s3BucketData: null,
};

const themeData = {
  themeInfoLoading: false,
  themeInfo: null,
  themeInfoError: null,
};

const authReducer = (state = { ...initailState, ...themeData }, action) => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        access_token: '',
        refresh_token: '',
        session_token: '',
        auth_error: null,
      };
    case AUTH_SUCCESS:
      return {
        ...state,
        access_token: action.payload.accessToken,
        refresh_token: action.payload.refreshToken,
        session_token: action.payload.sessionToken,
        userData: {
          userName: action?.payload?.userName,
          preferredUsername: action?.payload?.preferredUsername,
          userDevice: action?.payload?.deviceData,
        },
      };
    case AUTH_FAIL:
      return {
        ...state,
        auth_error: action.payload,
      };
    case UPDATE_AUTH:
      return {
        ...state,
        access_token: action.payload,
      };
    case ADD_ADMIN_USER:
      return {
        ...state,
        isAdminUser: action.payload,
      };
    case CHANGE_ADMIN_VIEW:
      return {
        ...state,
        isAdminView: action.payload,
      };
    case FETCH_USER_DATA_REQUEST:
      return {
        ...state,
        authUserDataLoading: true,
        authUserDataError: null,
      };
    case FETCH_USER_DATA_SUCCESS:
      return {
        ...state,
        authUserDataLoading: false,
        authUserDataError: null,
        userData: {
          ...state.userData,
          ...action.payload.userData,
          groupInfo: action.payload.groupWithRoles,
          roleList: findRolesList(action.payload.groupWithRoles),
        },
      };
    case FETCH_USER_DATA_FAIL:
      return {
        ...state,
        authUserDataLoading: false,
        authUserDataError: {
          message: messageConfigs.error.USER.USER_DATA_FETCH_FAIL,
        },
      };
    case SET_WELCOME_VIDEO_VIEWED:
      return {
        ...state,
        isWelcomeVideoViewed: true,
      };
    case CLEAR_SESSION:
      return {
        ...state,
        ...themeData,
        ...initailState,
      };
    case FETCH_RESET_PASSWORD_DATA_REQUEST:
      return {
        ...state,
        resetPasswordUserDataRequestLoading: true,
        resetPasswordUserData: null,
        resetPasswordUserDataError: null,
      };
    case FETCH_RESET_PASSWORD_DATA_SUCCESS:
      return {
        ...state,
        resetPasswordUserDataRequestLoading: false,
        resetPasswordUserData: action.payload,
        resetPasswordUserDataError: null,
      };
    case FETCH_RESET_PASSWORD_DATA_FAIL:
      return {
        resetPasswordUserDataRequestLoading: false,
        resetPasswordUserData: null,
        resetPasswordUserDataError: action.payload,
      };

    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordReqLoading: true,
        resetPasswordReqSuccess: false,
        resetPasswordReqFail: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordReqLoading: false,
        resetPasswordReqSuccess: true,
        resetPasswordReqFail: false,
      };
    case RESET_PASSWORD_FAIL:
      return {
        resetPasswordReqLoading: false,
        resetPasswordReqSuccess: false,
        resetPasswordReqFail: true,
      };

    case FETCH_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        themeInfoLoading: true,
        themeInfo: null,
        themeInfoError: null,
      };

    case FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        themeInfoLoading: false,
        themeInfo: action.payload,
        themeInfoError: null,
      };

    case FETCH_COMPANY_PROFILE_FAIL:
      return {
        themeInfoLoading: false,
        themeInfo: null,
        themeInfoError: action.payload,
      };

    case SYNC_FEDERATE_LOGIN_DATA_REQUEST:
      return {
        ...state,
        federateLoginData: null,
        federatedLoginError: false,
      };

    case SYNC_FEDERATE_LOGIN_DATA_SUCCESS:
      return {
        ...state,
        federateLoginData: action.payload,
        federatedLoginError: false,
      };

    case SYNC_FEDERATE_LOGIN_DATA_FAIL:
      return {
        ...state,
        federateLoginData: null,
        federatedLoginError: true,
      };
    case SET_IS_FEDERATED_LOGIN:
      return {
        ...state,
        isFederateLogin: true,
      };

    case SET_GEO_LOCATION:
      return {
        ...state,
        geoLocation: action?.payload,
      };
    case FETCH_S3_BUCKET_DATA_REQUEST:
      return {
        ...state,
        s3BucketDataLoading: true,
        s3BucketData: null,
      };

    case FETCH_S3_BUCKET_DATA_SUCCESS:
      return {
        ...state,
        s3BucketDataLoading: false,
        s3BucketData: action?.payload,
      };

    case FETCH_S3_BUCKET_DATA_FAIL:
      return {
        ...state,
        s3BucketDataLoading: false,
        s3BucketData: null,
      };

    default:
      return state;
  }
};

export default authReducer;
