export const messageConfigs = {
  success: {
    video: {
      PUBLISH: 'Video published successfully',
      SAVE: 'Video saved successfully',
      UPDATE: 'Video updated successfully',
      PUBLISH_BANNER: 'Banner video published successfully',
      SAVE_BANNER: 'Banner video saved successfully',
      UPDATE_BANNER: 'Banner video updated successfully',
      PUBLISH_FEATURED: 'Featured video published successfully',
      SAVE_FEATURED: 'Featured video saved successfully',
      UPDATE_FEATURED: 'Featured video updated successfully',
    },
    document: {
      PUBLISH: 'Document published successfully',
      SAVE: 'Document saved successfully',
      UPDATE: 'Document updated successfully',
      PUBLISH_BANNER: 'Banner document published successfully',
      SAVE_BANNER: 'Banner document saved successfully',
      UPDATE_BANNER: 'Banner document updated successfully',
      PUBLISH_FEATURED: 'Featured document published successfully',
      SAVE_FEATURED: 'Featured document saved successfully',
      UPDATE_FEATURED: 'Featured document updated successfully',
    },
    standaloneAssessment: {
      PUBLISH: 'Standalone assessment published successfully',
      SAVE: 'Standalone assessment saved successfully',
      UPDATE: 'Standalone assessment updated successfully',
      PUBLISH_BANNER: 'Banner standalone assessment published successfully',
      SAVE_BANNER: 'Banner standalone assessment saved successfully',
      UPDATE_BANNER: 'Banner standalone assessment updated successfully',
      PUBLISH_FEATURED: 'Featured standalone assessment published successfully',
      SAVE_FEATURED: 'Featured standalone assessment saved successfully',
      UPDATE_FEATURED: 'Featured standalone assessment updated successfully',
    },
    midVideoAssessment: {
      PUBLISH: 'Mid video assessment published successfully',
      SAVE: 'Mid video assessment saved successfully',
      UPDATE: 'Mid video assessment updated successfully',
    },
    endVideoAssessment: {
      PUBLISH: 'End video assessment published successfully',
      SAVE: 'End video assessment saved successfully',
      UPDATE: 'End video assessment updated successfully',
    },
    image: {
      PUBLISH_BANNER: 'Banner image published successfully',
      SAVE_BANNER: 'Banner image saved successfully',
      UPDATE_BANNER: 'Banner image updated successfully',
      PUBLISH_FEATURED: 'Featured image published successfully',
      SAVE_FEATURED: 'Featured image saved successfully',
      UPDATE_FEATURED: 'Featured image updated successfully',
    },
    user: {
      SELF_SIGNUP:
        'You have successfully signed up! You will receive an email as soon as an administrator approves your request.',
      PASSWORD_CHANGED: 'Your password has been changed successfully!',
      WELCOME_VIDEO_REMOVED: 'Welcome video has been removed successfully!',
      FORGOT_PASSWORD:
        'Forgot your password? Enter the email address associated with your Koach account.',
      FORGOT_PASSWORD_EMAIL_SENT:
        'We have emailed you a verification code to the email provided. Please enter the code below along with a new password',
      USER_REQUEST_ACCEPTED: 'Requests Accepted',
    },
    notification: {
      UPDATE_USER: 'User notification settings updated successfully',
      UPDATE_ADMIN: 'Administrator notification settings updated successfully',
    },
  },
  error: {
    COMMON:
      'An error occurred. Please check if you have filled in all the mandatory fields and try again.',
    CONTACT_SUPPORT: 'An error occurred. Please contact support',
    TRY_LATER: 'Sorry there seems to be a problem, please try again later',
    SELF_SIGNUP: {
      ALREADY_EXISTS: 'Username or email already exists!',
      INVALID_EMAIL: 'Please provide a valid email address',
      INVALID_USER_NAME: 'Please provide a valid username',
      INVALID_NAME: 'Please provide a valid name',
    },
    STATS: {
      ASSESSMENT: 'Assessment Stats Downloading Failed',
      TRANSCRIPT: 'Assessment Transcript Downloading Failed',
      VIDEO: 'Video Stats Downloading Failed',
    },
    REEL: {
      ALREADY_EXISTS:
        'This reel already exists. Please either add it to the group or ask someone who has access to the reel to add it to the group.',
    },
    USER: {
      PASSWORD_NOT_MATCH: "Passwords don't match!",
      WRONG_CURRENT_PASSWORD: 'Wrong current password',
      WRONG_CONFIRM_PASSWORD: 'Wrong confirm password',
      INVALID_PASSWORD: 'Must contain at least 8 or more characters',
      INCORRECT_USERNAME_PASSWORD: 'Incorrect username or password.',
      SUSPENDED_USER:
        'You have been suspended from the system, please contact your administrator.',
      GROUPS_NOT_ASSIGNED:
        "You haven't been assigned to a group yet, please contact your administrator",
      RESET_PASSWORD_TOKEN_EXPRIRED: 'Token Expired, Please request again',
      DOWNLOAD_USER_REPORT: 'User Report Downloading Failed',
      DOWNLOAD_USERS_LIST: 'Users List Downloading Failed',
      DISABLED: 'User is disabled.',
      WELCOME_VIDEO_LENGTH: 'Maximum character limit reached',
      FEDERATE_LOGIN_FAIL:
        'Your account is not enabled for this system. Please contact your administrator!',
      INVALID_CODE: 'Invalid verification code',
      USER_DATA_FETCH_FAIL: 'An error occurred. Please contact support',
    },
  },
  deleteConfirm: {
    title: 'Delete',
    video: 'Are you sure you wish to delete this Video?',
    document: 'Are you sure you wish to delete this Document?',
    assessment: 'Are you sure you wish to delete this Assessment?',
    user: 'Are you sure you wish to delete this User?',
    userFromGroup: 'Are you sure you wish to remove this User?',
    image: 'Are you sure you wish to delete this Image?',
    reel: 'Are you sure you wish to delete this Reel?',
    errorMessageStarting: 'This User ',
    errorMessageEnding:
      ' is a active user in rms, please delete this user from rms to continue.',
  },
  mandatoryReelRemoveConfirm: {
    title: 'Remove',
    video:
      'Are you sure you wish to remove this Video from the Mandatory reel?',
    document:
      'Are you sure you wish to remove this Document from the Mandatory reel?',
    assessment:
      'Are you sure you wish to remove this Assessment from the Mandatory reel?',
    image:
      'Are you sure you wish to remove this Image from the Mandatory reel?',
  },
};

export default messageConfigs;
