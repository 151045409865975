import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Router as ReactRouter, Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { deps } from '../redux/store';

import AuthenticatdRoute from './AuthenticatedRoute';
import Login from '../Container/Login';
import SignUp from '../Container/SignUp';
import SmallScreenMessage from '../Components/SmallSreenMessage/ScreenSizeMessage';
import Home from '../Container/HomePage/Home';
import Theatre from '../Container/Theatre';
import Header from '../Container/Header';
import Footer from '../Container/Footer';
import Assessment from '../Container/Assessment';
import ReelBulkView from '../Container/BulkViewPage/ReelBulkView';
import DocumentBulkView from '../Container/BulkDocumentViewPage/DocumentBulkView';
import CreateVideo from '../Container/AdminDashboard/Global/Video/CreateVideo';
import CreateAssessment from '../Container/AdminDashboard/Global/Assessment/CreateAssessment';
import CreateDocument from '../Container/AdminDashboard/Global/Document/CreateDocument';
import CreateBannerFeatureVideo from '../Container/HomePage/BannerAndFeatureCreation/CreateVideo';
import CreateBannerFeatureDocument from '../Container/HomePage/BannerAndFeatureCreation/CreateDocument';
import CreateBannerFeatureAssessment from '../Container/HomePage/BannerAndFeatureCreation/CreateAssessment';
import CreateBannerFeatureImage from '../Container/HomePage/BannerAndFeatureCreation/CreateImage';
import CreateUser from '../Container/AdminDashboard/Global/Users/CreateUser';
import AllUsersTable from '../Container/AdminDashboard/Global/Users/AllUsersTable';
import UserRequests from '../Container/AdminDashboard/Global/Users/UserRequests';
import nodeTree from '../Container/AdminDashboard/Global/nodeTree';
import GroupDashboard from '../Container/AdminDashboard/Group/Groups/GroupDashboard';
import SearchReels from '../Container/AdminDashboard/Global/Reels/AllReel';
import SingleReel from '../Container/AdminDashboard/Global/Reels/SingleReel';
import AllVideos from '../Container/AdminDashboard/Global/Video/AllVideos';
import AllAssessments from '../Container/AdminDashboard/Global/Assessment/AllAssessments';
import AllDocuments from '../Container/AdminDashboard/Global/Document/AllDocument';
import VideoStats from '../Container/AdminDashboard/Stats/VideoStats';
import ProfileView from '../Container/AdminDashboard/Global/Users/ProfileView';
import useFetchDataApi from '../Hooks/useFetchDataApi';
import Settings from '../Container/Settings';
import NotificationBulkView from '../Container/Notification/notificationBulkView';
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword';
import Websocket from '../Container/websocket/websocket';
import RmsRoutes from '../rms/routes';

const Routes = () => {
  const { history } = deps;
  history.listen(location => {
    if (location.action === 'POP') {
      return;
    }
    window.scrollTo(0, 0);
  });
  const { allGroupsFetchData } = useSelector(state => state?.group);
  const { access_token, userData, isAdminView, themeInfo } = useSelector(
    state => state.auth && state.auth
  );
  const [fetchData] = useFetchDataApi();

  const isAuthenticated = access_token;

  // Disabling right click in the app
  useEffect(() => {
    document.addEventListener('contextmenu', event => {
      event.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (!themeInfo) {
      fetchData({
        type: 'FETCH_THEME_DATA',
        objectId: process.env.REACT_APP_CLIENT_ID,
      });
    }
  }, [themeInfo]);

  useEffect(() => {
    if (
      Array.isArray(allGroupsFetchData) &&
      !allGroupsFetchData.length &&
      isAuthenticated
    ) {
      fetchData({ type: 'ALL_GROUPS' });
    }
  }, [allGroupsFetchData, isAuthenticated]);
  useEffect(() => {
    if (themeInfo?.name) {
      const body = document.getElementsByTagName('BODY')[0];
      body.className = `${
        body.className
      } theme--${(themeInfo?.name).toLowerCase()}`;
    }
  }, [themeInfo]);

  useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    if (isAdminView) {
      body.className = body.className.concat(' admin');
    } else {
      body.className = body.className.replace('admin', '');
    }
    return () => {
      body.className.replace('admin', '');
    };
  }, [isAdminView]);

  const isSmallScreen = window?.screen?.width < 1024 && isAdminView;

  return (
    <ReactRouter history={history}>
      <ConnectedRouter history={history}>
        <Websocket />
        {themeInfo ? (
          isSmallScreen ? (
            <SmallScreenMessage
              width={window?.screen?.width}
              logo={themeInfo?.headerLogoUrl}
            />
          ) : (
            <>
              {isAuthenticated &&
                userData &&
                userData.groupInfo &&
                userData.roleList && <Header history={history} />}
              <div className="content-area--wrapper">
                <Switch>
                  {/* user routes */}
                  <Route exact path="/" component={Login} />
                  <Route
                    exact
                    path="/signup"
                    component={SignUp}
                    history={history}
                  />
                  <Route exact path="/reset/changePassword" component={Login} />
                  <Route
                    path="/forgotPassword"
                    component={ForgotPassword}
                    history={history}
                  />
                  <AuthenticatdRoute
                    path="/notifications-all"
                    component={NotificationBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isCommon
                  />
                  <AuthenticatdRoute
                    exact
                    path="/home"
                    component={Home}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                    user
                  />
                  <AuthenticatdRoute
                    exact
                    path="/home/:groupId"
                    component={Home}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/theatre/:videoId"
                    component={Theatre}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                    isCommon
                  />
                  <AuthenticatdRoute
                    exact
                    path="/theatre/:groupId/:videoId"
                    component={Theatre}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/:groupId/video/:reelId"
                    component={ReelBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/:groupId/document/:reelId"
                    component={DocumentBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/:groupId/document/:reelId/:categoryId"
                    component={DocumentBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/document/:reelId/:categoryId"
                    component={DocumentBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    user
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/:groupId/video/reel/:type"
                    component={ReelBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/video/:reelId"
                    component={ReelBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    user
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/video/reel/:type"
                    component={ReelBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    user
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/document/:reelId"
                    component={DocumentBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    user
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reel-bulk-view/video/"
                    component={ReelBulkView}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    user
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/assessment/:assessmentId"
                    component={Assessment}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isCommon
                  />
                  <AuthenticatdRoute
                    exact
                    path="/assessment/:assessmentId/:isEndVideo/:videoId"
                    component={Assessment}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isCommon
                  />
                  {/* Admin routes */}
                  <AuthenticatdRoute
                    exact
                    path="/create-video"
                    component={CreateVideo}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-video/group/:groupId/reel/:reelId"
                    component={CreateVideo}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-video/:videoId"
                    component={CreateVideo}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-assessment/:assessmentType?"
                    history={history}
                    component={CreateAssessment}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-document"
                    component={CreateDocument}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-document/group/:groupId/reel/:reelId"
                    component={CreateDocument}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-document/:documentId"
                    component={CreateDocument}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-assessment/:assessmentId"
                    component={CreateAssessment}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />

                  <AuthenticatdRoute
                    exact
                    path="/create-video/:type/group/:groupId"
                    component={CreateBannerFeatureVideo}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-video/:type/:id"
                    component={CreateBannerFeatureVideo}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-assessment/standalone/:type/group/:groupId"
                    component={CreateBannerFeatureAssessment}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-assessment/:type/:id"
                    component={CreateBannerFeatureAssessment}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-document/:type/group/:groupId"
                    component={CreateBannerFeatureDocument}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-document/:type/:id"
                    component={CreateBannerFeatureDocument}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/create-image/:type/group/:groupId"
                    component={CreateBannerFeatureImage}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/edit-image/:type/:id"
                    component={CreateBannerFeatureImage}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />

                  <AuthenticatdRoute
                    exact
                    path="/create-user"
                    component={CreateUser}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/update-user/:userId/:isEdit"
                    component={CreateUser}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-users"
                    component={AllUsersTable}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-users/:isSignupUsers"
                    component={UserRequests}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/node-tree"
                    component={nodeTree}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/group-details"
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/users"
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/users/:userId/:isEdit"
                    component={GroupDashboard}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/create-group/:groupId"
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/reels/:reelType"
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/reels/:reelType/:reelId"
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />

                  <AuthenticatdRoute
                    exact
                    path="/search-reels/:reelType"
                    component={SearchReels}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-reels/:reelType/:reelId"
                    component={SingleReel}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-reels/:reelType/:reelId/:videoAssessmentId"
                    component={VideoStats}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/reels/:reelType/:reelId"
                    component={SingleReel}
                    history={history}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/reels/:reelId/:statsType/:videoAssessmentId"
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/assessments/:assessmentType"
                    history={history}
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/assessments/standalone/:reelId/:videoAssessmentId"
                    history={history}
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/group-dashboard/:groupId/assessments/:assessmentType/:assessmentId"
                    history={history}
                    component={GroupDashboard}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-videos"
                    history={history}
                    component={AllVideos}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-assessments/:assessmentType"
                    component={AllAssessments}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/search-documents"
                    component={AllDocuments}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                  />
                  <AuthenticatdRoute
                    exact
                    path="/profile"
                    component={ProfileView}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                    isCommon
                  />
                  <AuthenticatdRoute
                    exact
                    path="/settings/:settingsType?"
                    component={Settings}
                    isAuthenticated={isAuthenticated}
                    isAdminView={isAdminView}
                    isCommon
                  />
                  <AuthenticatdRoute
                    path="/rms"
                    component={RmsRoutes}
                    isAuthenticated={isAuthenticated}
                    isCommon
                  />
                </Switch>
              </div>
              <Footer />
            </>
          )
        ) : null}
      </ConnectedRouter>
    </ReactRouter>
  );
};

export default Routes;
